const lang = {
    en: {
        AT_LEAST: `at least`,
        CHARACTERS_LONG: `characters long`,
        CHARACTERS_OR_LESS: `characters or less`,
        EMAIL_ADDRESS: `Email Address`,
        EMAIL_IS_REQUIRED: `Email is required`,
        FULL_NAME: `Full Name`,
        FULL_NAME_REQUIRED: `Full Name is required`,
        FULL_NAME_MUST_BE: `Full Name must be`,
        INVALID_EMAIL_ADDRESS: `Invalid email address`,
        MESSAGE_MUST_BE: `Your message must be`,
        MESSAGE_SENT: `Thank you, your message has been sent!`,
        MESSAGE_TEXT_IS_REQUIRED: `A message text is required`,
        ONE_SECOND: `One second please...`,
        SENDING_FAILED: `Oops :-( sending failed. Error`,
        PLEASE_SELECT: `Please select...`,
        PLEASE_SELECT_SUBJECT: `Please select one subject`,
        SUBMIT: `Submit`,
        YOUR_MESSAGE: `Your message`,
    },
    de: {
        AT_LEAST: `wenigstens`,
        CHARACTERS_LONG: `Zeichen lang`,
        CHARACTERS_OR_LESS: `Zeichen oder weniger`,
        EMAIL_ADDRESS: `Email Adresse`,
        EMAIL_IS_REQUIRED: `Email ist erforderlich`,
        FULL_NAME: `Vollständiger Name`,
        FULL_NAME_REQUIRED: `Bitte geben Sie Ihren Namen an`,
        FULL_NAME_MUST_BE: `Vollständiger Name muss`,
        INVALID_EMAIL_ADDRESS: `Fehlerhafte Email Adresse`,
        MESSAGE_MUST_BE: `Ihre Nachricht muss`,
        MESSAGE_SENT: `Danke, Ihre Nachricht wurde versendet!`,
        MESSAGE_TEXT_IS_REQUIRED: `Eine Nachricht ist erforderlich`,
        ONE_SECOND: `Einen Moment bitte...`,
        SENDING_FAILED: `Senden abgebrochen. Fehler`,
        PLEASE_SELECT: `Betreff wählen...`,
        PLEASE_SELECT_SUBJECT: `Bitte ein Thema wählen`,
        SUBMIT: `Abschicken`,
        YOUR_MESSAGE: `Ihre Nachricht an KS-Labs`,
    },
}

module.exports = lang